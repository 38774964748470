import React from 'react';

import { createQueryString } from '../../../lib/utils/search';
import Link from '../../Link';
import SectionHeader from '../SectionHeader';
import Text from './Text';

const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME;

const ofertas = [
  {
    _id: 1,
    lines: [
      // eslint-disable-next-line quotes
      <Text key={1} fs={26} ls={25}>
        tops
      </Text>,
      <Text key={2} fs={65}>
        S/.25
      </Text>,
      <Text key={3} fs={18} marginRight={15}>
        o menos
      </Text>,
    ],
    link: `/shop/${createQueryString({
      page: 1,
      refinementList: {
        universo: 'mujer',
      },
      hierarchicalMenu: {
        categoria: 'polo',
      },
      range: {
        precioVenta: {
          max: 25,
        },
      },
      sortBy: `${indexName}_precioVenta_asc`,
      message: 'Tops S/. 25 o menos',
    })}`,
  },
  {
    _id: 2,
    lines: [
      <Text key={1} fs={26}>
        accesorios
      </Text>,
      <Text key={2} fs={65} marginRight={15}>
        S/.5
      </Text>,
      <Text key={3} fs={18} marginRight={15}>
        desde
      </Text>,
    ],
    link: `/shop/${createQueryString({
      page: 1,
      refinementList: {
        universo: 'mujer',
      },
      hierarchicalMenu: {
        categoria: 'accesorios',
      },
      sortBy: `${indexName}_precioVenta_asc`,
      message: 'Accesorios desde S/. 5',
    })}`,
  },
  {
    _id: 3,
    lines: [
      <Text key={1} fs={26} ls={5}>
        vestidos
      </Text>,
      <Text key={2} fs={65}>
        S/.40
      </Text>,
      <Text key={3} fs={18} marginRight={15}>
        o menos
      </Text>,
    ],
    link: `/shop/${createQueryString({
      page: 1,
      refinementList: {
        universo: 'mujer',
      },
      hierarchicalMenu: {
        categoria: 'vestido / enterizo > vestido',
      },
      range: {
        precioVenta: {
          max: 40,
        },
      },
      sortBy: `${indexName}_precioVenta_asc`,
      message: 'Vestidos S/. 40 o menos',
    })}`,
  },
  {
    _id: 4,
    lines: [
      <Text key={1} fs={26} ls={5}>
        abrígate
      </Text>,
      <Text key={2} fs={65}>
        S/.50
      </Text>,
      <Text key={3} fs={18} marginRight={15}>
        o menos
      </Text>,
    ],
    link: `/shop/${createQueryString({
      page: 1,
      refinementList: {
        universo: 'mujer',
      },
      hierarchicalMenu: {
        categoria: 'chompa / cardigan',
      },
      range: {
        precioVenta: {
          max: 50,
        },
      },
      sortBy: `${indexName}_precioVenta_asc`,
      message: 'Abrígate por S/. 50 o menos',
    })}`,
  },
];

const SaleSection = () => (
  <div className="bg-[#ffe28f] pt-4 pb-2">
    <SectionHeader>¡Cientos de prendas a precios imbatibles!</SectionHeader>
    <div className="w-full flex flex-wrap box-border justify-center">
      {ofertas.map((s) => (
        <div
          key={s._id}
          className="
              h-32 md:h-[280px]
              text-3xl text-center font-bold ml-auto mr-auto
              pt-0 pl-10 md:pt-12 md:pt-auto md:pl-0 mb-6
              bg-contain bg-no-repeat flex-grow-0 justify-center items-center
              flex-col m-0 box-border w-full flex flex-wrap
              min-h-[150px] md:min-h-[280px]
              bg-center bg-[url(/images/etiqueta-horizontal.png)]
              md:bg-[url(/images/etiqueta.png)]
              max-w-[100%] md:max-w-[20%]
            "
        >
          <Link href={s.link} passHref>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="flex flex-col">{s.lines}</a>
          </Link>
        </div>
      ))}
    </div>
  </div>
);

export default SaleSection;
