import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { colorPrimary } from '../../utils/elements/muiStyles';

const Typography2 = (props) => {
  const newProps = { ...props };
  delete newProps.marginRight;
  return <Typography {...newProps} />;
};

export default withStyles((theme) => ({
  root: ({
    fs: fontSize,
    ls: letterSpacing = 1,
    lh: lineHeight = 1,
    ...rest
  }) => ({
    fontFamily: 'Kanit, Arial, Verdana, sans-serif',
    fontDisplay: 'swap',
    textTransform: 'uppercase',
    color: colorPrimary,
    fontWeight: 'bolder',

    fontSize,
    lineHeight,
    letterSpacing,
    margin: 'auto',
    paddingLeft: letterSpacing - 1,
    [theme.breakpoints.down('md')]: {
      fontSize: fontSize * 1,
    },
    ...rest,
  }),
}))(Typography2);
