import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ children }) => (
  <p
    className="
      text-2xl md:text-3xl text-center text-black
      font-extrabold tracking-tight leading-normal
      mb-4 md:mb-10 m-0
    "
  >
    {children}
  </p>
);

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionHeader;
